import React from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import "scss/retro.scss";

class page404 extends React.Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Error : 404</title>
                </Helmet>
                <div className="error-404">
                    <div>
                        <h1>404</h1>
                        <h2>Oops!</h2>
                        <h3>Couldn't find that page! </h3>
                        <p>Let's go back.</p>
                        <Link to="/">Home</Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default page404;
